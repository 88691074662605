import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';

import { MdDeleteForever } from "react-icons/md";

import styles from "./index.module.scss";

import { postImages360, deleteVehicleImage360 } from "@services/Vehicle/vehicle.service";

import { ErrorPanel } from "@components/InfoBox";
import { errorToast } from "@helpers/toastFunctions";

const Url360Uploader = ({ id, image360Link, setImage360Link }) => {
  const [showPreviewer, setShowPreviewer] = useState(false);
  const [formatError, setFormatError] = useState(false);
  const [textLink, setTextLink] = useState("");

  const { t } = useTranslation('vehicleValidation');

  const URL_360_FORMAT = "https://assets.spyne.ai/360";

  useEffect(() => {
    if (image360Link.length) {
      setShowPreviewer(true);
    }
  }, [image360Link]);

  useEffect(() => {
    if (textLink) {
      setFormatError(false);
    }
  }, [textLink]);

  const uploadLinkHandler = async () => {
    if (textLink.startsWith(URL_360_FORMAT)) {
      try {
        const response = await postImages360(id, textLink);
        if (response) {
          setShowPreviewer(true);
          setImage360Link([response.data]);
        }
      } catch (e) {
        errorToast(t("errors.errorLink360"));
      }
    } else {
      setFormatError(true);
    }
  };

  const deleteLinkHandler = async (linkId) => {
    if (image360Link.length) {
      try {
        await deleteVehicleImage360(id, linkId);
        setImage360Link([]);
        setTextLink("");
        setShowPreviewer(false);
      } catch (e) {
        errorToast(t("errors.errorDeleteLink360"));
      }
    }
  };

  return (
    <div className="my-3" >
      {!showPreviewer ?
        <>
          <div className="d-flex">
            <div className="col-md-6 col-sm-12 p-0">
              <input
                placeholder={t("addLink")}
                type="text"
                value={textLink}
                onChange={(e) => setTextLink(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-12 d-flex">
              <button className="btn btn-light btn-outline-dark btn-flexicar"
                disabled={!textLink}
                onClick={() => uploadLinkHandler()}>
                {t("uploadLink")}
              </button>
            </div>
          </div>
          {formatError &&
            <div className="col-md-6 col-sm-12 p-0 pt-2">
              <ErrorPanel text="Formato url 360 inválido" />
            </div>}
        </>
        :
        <div className="my-3 d-flex flex-column align-items-center">
          <div className="d-flex mb-1">
            <span className={styles.linkTitle}>
              {t("linkViewer")}
            </span>
            <div className={styles.deleteIcon}>
              <MdDeleteForever
                size={22}
                onClick={() => deleteLinkHandler(image360Link[0].id)}
              />
            </div>
          </div>
          <iframe src={image360Link[0]?.url} className={styles.previewer} title="360 viewer" />
        </div>}
    </div>
  );
};

export default Url360Uploader;
