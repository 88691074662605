import React, { useState } from "react";
import "./index.css";
import { Spinner } from "reactstrap";
import { deleteVehicleImage, postImagesRequest } from "@services/Vehicle/vehicle.service";
import { errorToast } from "@helpers/toastFunctions";
import { WarningPanel } from "@components/InfoBox";
import { useTranslation } from "react-i18next";
import Uploader from "@views/VehicleValidation/components/Uploader";
import Preview from "@views/VehicleValidation/components/Preview";
import Url360Uploader from "@views/VehicleValidation/components/Url360Uploader";

const Gallery = ({
  imagesPreviewUrls,
  setImagesPreviewUrls,
  image360Link,
  setImage360Link,
  vehicle_id,
  type,
}) => {
  const [imageLoad, setImageLoad] = useState(true);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("vehicleValidation");

  const getImagesPreviewUrls = (result) => {
    setImagesPreviewUrls((oldArray) => [...oldArray, result]);
  };
  const deleteImage = async (id) => {
    if (imagesPreviewUrls.length > 0) {
      try {
        const filterImages = await imagesPreviewUrls.filter(
          (image) => image.id !== id
        );

        await setImagesPreviewUrls(filterImages);
        await deleteVehicleImage(vehicle_id, id, type);
      } catch (e) {
        errorToast(e);
      }
    }
  };

  const onClickImagesRequest = async () => {
    setLoading(true)
    try {
      await postImagesRequest(vehicle_id);
      setRequestInProcess(true);
      setLoading(false);
    } catch (e) {
      errorToast(t("errors.errorImagesRequest"));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="my-4">
          <button
            className="btn btn-light btn-outline-dark btn-flexicar"
            onClick={() => onClickImagesRequest()}
            disabled={loading || requestInProcess}
          >
            <span>{t("imagesRequest")} {loading && <Spinner size={"sm"} />}</span>
          </button>
          <span className="ml-3">{t("imagesRequestMessage")}</span>
        {requestInProcess && <div className="d-flex mt-2" >
          <WarningPanel text={t("imagesRequestWarning")} />
        </div>}
      </div>
      <Url360Uploader
        id={vehicle_id}
        image360Link={image360Link}
        setImage360Link={setImage360Link}
      />
      <Uploader
        imagesPreviewUrls={imagesPreviewUrls}
        getImagesPreviewUrls={getImagesPreviewUrls}
        id={vehicle_id}
        imageLoad={imageLoad}
        setImageLoad={setImageLoad}
        type={type}
      />

      {imageLoad ? (
        <Preview
          imagesPreviewUrls={imagesPreviewUrls}
          getImagesPreviewUrls={getImagesPreviewUrls}
          deleteImage={deleteImage}
          setImagesPreviewUrls={setImagesPreviewUrls}
          vehicle_id={vehicle_id}
          type={type}
        />
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <Spinner type="grow" className="flexicarColor" />
          <p className="flexicarColor">{t("loadingImages")}</p>
        </div>
      )}
    </div>
  );
};

export default Gallery;
